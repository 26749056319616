import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"

import GMap from "../components/Map"

const KontaktyPage = () => {
  const data = useStaticQuery(graphql`
    query Kontakty {
      scomos {
        p1: pageItem(id: "/api/page_items/275") {
          name
          content
        }
        p2: pageItem(id: "/api/page_items/270") {
          name
          content
        }
        p3: pageItem(id: "/api/page_items/271") {
          name
          content
        }
        p4: pageItem(id: "/api/page_items/273") {
          name
          content
        }
        p5: pageItem(id: "/api/page_items/276") {
          name
          content
        }
        p6: pageItem(id: "/api/page_items/278") {
          name
          content
        }
        p7: pageItem(id: "/api/page_items/330") {
          name
          content
        }
      }
    }
  `)

  const boxes = data?.scomos

  // Move this to it's own component consuming context.

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kontakty | Holding Malgen</title>
      </Helmet>
      <main className="pb-8">
        {/* Hero card */}
        <div className="relative h-96">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-200" />
          <StaticImage
            src="../images/key.jpg"
            className="h-full w-full absolute object-cover border border-gray-200 "
            alt="Malgen"
          />
          <div className="absolute bottom-0  sm:overflow-hidden w-full border-t border-gray-400">
            <div className="absolute inset-0 bottom-0">
              <div className="absolute inset-0 bg-gray-300 mix-blend-multiply backdrop-filter backdrop-blur-sm" />
            </div>
            <div className="relative px-4 max-w-7xl mx-auto">
              <h1 className="text-center  text-2xl lg:text-3xl grid py-6 px-3 ">
                <span className=" text-white">Kontakty</span>
                <span className=" text-white text-2xl"></span>
              </h1>
            </div>
          </div>
        </div>
        <div className="  pt-12 sm:pt-16">
          <div className="py-10  bg-white  relative  ">
            <div aria-hidden="true" className="block absolute inset-y-0 w-full">
              <div className="absolute inset-y-0 right-10 lg:right-20 2xl:right-60 w-full bg-gray-100 rounded-r-3xl " />
            </div>

            <div className="relative">
              <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-6xl mx-auto ">
                  <div className=" grid grid-cols-1 lg:grid-cols-4 lg:justify-items-center relative">
                    <div className="md:col-span-2 lg:col-span-2 px-5 max-w-xl lg:max-w-3xl py-6 lg:py-0 lg:pr-20 ">
                      <div className="px-6 lg:px-0 py-2">
                        <div
                          className="mt-2 text-base text-gray-500 prose"
                          dangerouslySetInnerHTML={{
                            __html: boxes.p2.content,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="mt-2 w-full lg:col-span-2 text-base text-gray-500 prose"
                      dangerouslySetInnerHTML={{ __html: boxes.p3.content }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-5">
            <GMap />
          </div>

          <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-5">
            <div className=" grid grid-cols-1 lg:grid-cols-3 lg:justify-items-center relative">
              <div
                className="mt-2 w-full lg:col-span-2 text-base text-gray-500 prose"
                dangerouslySetInnerHTML={{ __html: boxes.p4.content }}
              ></div>
              <div className=" px-5 max-w-xl lg:max-w-3xl py-6 lg:py-0 lg:pr-20 ">
                <div className="px-6 lg:px-0 py-2">
                  <div
                    className="mt-2 text-base text-gray-500 prose"
                    dangerouslySetInnerHTML={{ __html: boxes.p5.content }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-5">
            <div
              className="mt-2 w-full  text-base text-gray-500 prose max-w-none"
              dangerouslySetInnerHTML={{ __html: boxes.p6.content }}
            ></div>
          </div>
          <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-5">
            <div className="mt-2 w-full  text-lg font-bold   prose max-w-none">
              {boxes.p7.name}
            </div>
            <div
              className="mt-2 w-full  text-base text-gray-500 prose max-w-none"
              dangerouslySetInnerHTML={{ __html: boxes.p7.content }}
            ></div>
          </div>
        </div>

        <div className="  pt-12 sm:pt-16">
          <div className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
                  Kávu platíme my
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Pomáháme firmám i jednotlivcům
                </p>
                <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                  K vašim potřebám přistoupíme individuálně. Je to jednoduché,
                  zavolejte nám nebo pošlete e-mail. Na základě Vašich požadavků
                  zašleme nabídku, nebo si s vámi dáme schůzku u dobré kávy.
                </p>
              </div>

              <div className="mt-10 grid justify-start space-y-8 lg:flex lg:justify-center lg:space-x-8 lg:space-y-0">
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-xl  font-medium text-gray-900">
                    800 991 100
                  </p>
                </div>
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <MailIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <a
                    href="mailto:office@malgen.cz"
                    className="ml-3 text-xl  font-medium text-gray-900"
                  >
                    office@malgen.cz
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default KontaktyPage
