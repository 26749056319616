import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '300px'
};

const center = {
  lat: 50.0766106,
  lng: 14.4271144
};

export default function GMap() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCUH2qcKpbJJ-dd9ux-p3bZQ3sihfctHDs"
  })

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
      
      >
        <Marker 
            position={center}
            />
        <></>
      </GoogleMap>
  ) : <></>
}
